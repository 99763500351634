<template>
  <div class="container">
    <div class="address-book">
      <div v-for="item in contactList" :key="item.id" class="book">
        <div>
          <div class="book__contactor">
            <van-tag
              v-if="item.def == 1"
              style="margin-right: 10px"
              type="danger"
              >{{ $t("默认") }}</van-tag
            >
            <span>{{ item.name }}</span
            ><span decode>&nbsp;&nbsp;{{ item.mobile }}</span>
          </div>
          <div class="book__address">
            {{ item.address }} {{ item.postalCode }}
          </div>
        </div>
        <div class="book__action">
          <div class="book__action-space">
            <van-checkbox
              v-if="scene != 'edit'"
              @change="chooseAddress(item)"
              v-model="item.select"
              checked-color="#07c160"
              >{{ $t("选为收件地址") }}</van-checkbox
            >
          </div>
          <div @click="editContact(item)" class="book__action-edit">
            <van-icon name="edit" /> {{ $t("编辑") }}
          </div>
          <div @click="deleteContact(item)" class="book__action-delete">
            <van-icon name="delete" /> {{ $t("删除") }}
          </div>
        </div>
      </div>
    </div>
    <van-divider v-if="!more" contentPosition="center">{{
      $t("没有更多了")
    }}</van-divider>

    <div class="placeholder-div"></div>
    <div class="action">
      <van-button @click="addContact" round block type="primary">{{
        $t("新增地址")
      }}</van-button>
    </div>
  </div>
</template>

<script>
import { Divider, Checkbox, Icon, Button, Toast, Tag } from "vant";
import { EventBus } from "@/utils/event-bus.js";

import {
  getContactList,
  setDefaultContact,
  deleteContact,
} from "@/service/api";

export default {
  components: {
    [Divider.name]: Divider,
    [Checkbox.name]: Checkbox,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Tag.name]: Tag,
  },
  data() {
    return {
      contactList: [],
      more: true,
      select: false,
      scene: this.$route.query.scene,
      selectedAddressId: 0,
    };
  },
  mounted() {
    this.getContactList();
    this.select = this.$route.query.select;
  },
  methods: {
    chooseAddress(value) {
      this.selectedAddressId = value.id;

      const contact = JSON.stringify(value);
      EventBus.$emit("contact-select", contact);

      setTimeout(() => {
        this.$router.go(-1);
      }, 500);
    },
    editContact(item) {
      this.$router.push({
        name: "Contact",
        params: { id: item.id },
      });
    },
    async deleteContact(item) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("删除中..."),
      });

      const res = await deleteContact(item.id);
      if (res.success) {
        Toast.success("删除地址成功");
        this.getContactList();
        Toast.clear();
      } else {
        Toast.fail("删除地址失败");
      }
    },
    async setDefaultContact(item) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });
      const res = await setDefaultContact(item.id);

      if (res.success) {
        let contactList = this.contactList;
        let defContact = contactList.find((c) => 1 == c.def);
        let nextDefContact = contactList.find((c) => item.id == c.id);
        if (defContact) {
          defContact.def = 0;
        }
        if (nextDefContact) {
          nextDefContact.def = 1;
        }
        this.contactList = [...contactList];
        Toast.clear();
      } else {
        Toast.fail(res.message);
      }
    },
    onSelectContact(item) {
      if (this.select) {
        const contact = JSON.stringify(item);
        EventBus.$emit("contact-select", contact);
        this.$router.go(-1);
      }
    },
    addContact() {
      this.$router.push({
        name: "Contact",
        query: { total: this.contactList.length },
      });
    },
    async getContactList() {
      const res = await getContactList();
      if (res.success) {
        this.contactList = res.data;
        this.more = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  margin: 10px;
}
.book {
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
}
.book__contactor {
  margin-bottom: 10px;
  font-size: 16px;
}
.book__address {
  margin-bottom: 10px;
  font-size: 14px;
  color: #c6c6c6;
}

.book__action {
  border-top: 1px solid #f3f3f3;
  padding-top: 10px;
  display: flex;
  align-items: center;
}
.book__action-space {
  flex: 1;
}
.contactor__def-label {
  font-size: 14px;
}

.book__action-edit,
.book__action-delete {
  font-size: 14px;
  margin-left: 30px;
  text-align: right;
}

.placeholder-view {
  width: 100%;
  height: 60px;
}
.action {
  background-color: #ffffff;
  padding: 8px 30px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>